/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSolutionQuery } from '@querys/useSolutionQuery';
import { useSolutionSubcategoryQuery } from '@querys/useSolutionSubcategoryQuery';
import { useFilters, useSiteContext } from '@lib/context';
import { Link } from 'gatsby';

const FilterBlock = () => {
  const { updateSolution } = useFilters();
  const { state } = useSiteContext();
  const { solutions } = useSolutionQuery();
  const { subcats } = useSolutionSubcategoryQuery();

  const [solutionPath, setSolutionPath] = useState('/solutions?filters`');

  useEffect(() => {
    // check if state.solution has sub-solutions
    const solution = solutions?.filter(
      (sol) => sol?.slug?.current === state?.solution
    );

    const subcat = subcats?.filter(
      (sub) =>
        sub?.solutionCategory?.slug?.current === solution[0]?.slug?.current
    );

    if (subcat.length > 0 || state.solution === 'default') {
      setSolutionPath(`/solutions?filters`);
    } else {
      setSolutionPath(`/solutions/${state?.solution}`);
    }
  }, [state?.solution]);

  return (
    <div className='px-[34px] md:px-[80px] pt-[38px] md:pt-[60px]'>
      <h2 className='blockH2'>What are you looking for?</h2>

      <div className=' md:mt-[20px]'>
        <select
          name='solution'
          id=''
          className='homeFilterInput filterArrow mt-5 md:mt-14'
          onChange={(e) => {
            updateSolution(e.target.value);
          }}
        >
          <option disabled selected hidden>
            Finance Solution
          </option>
          <option value='default'>All Finance Solutions</option>
          {solutions?.map((solution, index) => (
            <option key={index} value={solution?.slug?.current}>
              {solution?.title}
            </option>
          ))}
        </select>
        <Link
          to={solutionPath}
          className='button-yellow mt-10 md:mt-[90px] mx-auto'
        >
          Search
        </Link>
      </div>
    </div>
  );
};

export default FilterBlock;
