import { useStaticQuery, graphql } from 'gatsby';

import { QueryFragments } from './queryFragments'; // eslint-disable-line

export const useSolutionSubcategoryQuery = () => {
  const { allSanitySolutionSubcategory } = useStaticQuery(graphql`
    query {
      allSanitySolutionSubcategory(sort: { fields: orderRank, order: ASC }) {
        subcats: nodes {
          title
          slug {
            current
          }
          snippet
          icon {
            ...ImageWithPreview
          }
          solutionCategory {
            title
            slug {
              current
            }
          }
        }
      }
    }
  `);
  return allSanitySolutionSubcategory || {};
};
