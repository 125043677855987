import React from 'react';
import { graphql } from 'gatsby';
import { SanityImage } from '@components/sanity/sanityImage';
import { motion } from 'framer-motion';
import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line
import loadable from '@loadable/component';
import ModuleZone from '~moduleZone';
import Layout from '@components/layout';
import { PageMeta } from '@components/pageMeta';
import { wrapper } from '../css/base/backgroundVideo.module.css';
import { useInView } from 'react-intersection-observer';
import FilterBlock from '@components/filter/filterBlock';
import VideoModule from '@components/sanity/videoModule';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const Player = loadable(() => import('react-player'));

const IndexPage = ({ preview = false, data, previewDoc }) => {
  const page = preview ? previewDoc : data?.page;
  const { seo, banner, modules } = page || {};

  const breakpoints = useBreakpoint();

  const { ref, inView } = useInView({
    threshold: 0.4,
    triggerOnce: true,
  });

  const variants = {
    hidden: {
      y: -100,
    },
    visible: {
      y: -180,
      transition: {
        duration: 1.5,
        ease: 'easeInOut',
      },
    },
  };

  return (
    <Layout disabledHeader={true}>
      <PageMeta {...seo} />
      <div className="h-full md:h-screen w-full relative flex flex-col bg-light-yellow ">
        {!banner?.isVideo ? (
          <SanityImage
            image={banner?.bgImage}
            className="fixed z-0 w-full h-full top-0 left-0 object-cover brightness-75"
          />
        ) : (
          <div
            className={`fixed z-0 w-full h-screen top-0 left-0 ${wrapper} -translate-y-[6px] `}
          >
            <Player
              url={banner?.video}
              playing={breakpoints.md ? true : false}
              // onPlay={() => setWait(false)}
              loop
              playsinline
              muted
              className=" w-full h-full top-0 left-0 object-cover brightness-75"
              width="100%"
              height="100%"
            />
          </div>
        )}

        <div className="h-screen   px-gutter flex pb-20 w-full ">
          <div className="  self-center pb-20 lg:pb-0 pt-40 relative ">
            <div className="mb-6 max-w-[725px]">
              <h1 className="blockH1 text-white mb-6  ">{banner?.title}</h1>
              <h2 className="blockH3 text-light-grey">{banner?.subtitle}</h2>
            </div>
            <div className="blockH5 text-white mt-10">
              <VideoModule linkText="Watch video" videoUrl={banner?.video} />
            </div>
          </div>
        </div>
        <div className="block bg-transparent absolute bottom-0 left-0 w-full ">
          <div className="curved " />
          <div className="bg-light-yellow h-[30px]" />
        </div>
      </div>

      <div
        className=" bg-light-yellow z-20 relative flex justify-end"
        // reduce bottom spacing
        // style={{ height: "calc(100vh - 20vh )" }}
      >
        <div className="hidden lg:block  w-full h-[350px]" />
        <motion.div
          layout
          ref={ref}
          variants={variants}
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
          className="hidden lg:block lg:w-[650px] h-[483px] bg-white rounded-[20px] mr-gutter transform absolute "
        >
          <FilterBlock />
        </motion.div>
        <div className=" md:hidden max-w-[312px]  h-[330px] bg-white rounded-[20px] mr-[13px] transform -translate-y-20  ">
          <FilterBlock />
        </div>
      </div>
      {modules && <ModuleZone {...modules} />}
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query homePageQuery {
    page: sanityHomePage {
      seo {
        ...SEO
      }
      banner {
        isVideo
        subtitle
        title
        video
        bgImage {
          ...ImageWithPreview
        }
      }
      modules {
        ...HomeModules
      }
    }
  }
`;
